<template>
  <BRow>
    <BCol md="8">
      <ListingOverview :listing="listingOverview" class="mb-4" />
      <div class="mb-5 d-block d-md-none" v-if="getUserType === 'user' && listing.broker">
        <div v-if="tenantsVerified" class="card">
          <p class="text-gray5 fw-medium mb-2">{{ $t("monthlyRent") }}</p>
          <p class="d-flex align-items-center">
            <span class="fs-24 fw-bold text-black"
              >{{ listing.price | formatCurrency }}
            </span>
            <span class="text-gray5 ml-2 fw-medium">/{{ $t("month") }}</span>
          </p>

          <div class="text-danger fw-bold" v-if="listing.assigned">
            {{ $t("Rented, this property is not available anymore") }}
          </div>
          <div
            class="text-danger fw-bold"
            v-else-if="
              listing.currentStatus == 'Assign a candidate' || listing.disable_viewing
            "
          >
            {{ $t("It’s not possible to request a viewing") }}
          </div>
          <div v-else>
            <BButton
              v-if="incomeSufficient"
              v-b-modal.schedule-modal
              variant="primary"
              class="d-block w-100 mt-4"
            >
              {{ $t("Schedule a viewing") }}
            </BButton>
            <BButton v-else disabled variant="primary" class="d-block w-100 mt-4">
              {{ $t("Schedule a viewing") }}
            </BButton>

            <BButton variant="outline-primary" class="d-block w-100 mt-4">
              {{ $t("ask_question") }}</BButton
            >

            <div class="d-flex align-items-center my-3">
              <TickBox v-if="incomeSufficient" />
              <TimesBox v-else />
              <div class="ml-2 ml-lg-3">
                <p class="mb-0 fw-bold">
                  {{
                    incomeSufficient
                      ? $t("singleListing.actions.sufficient")
                      : $t("singleListing.actions.insufficient")
                  }}
                </p>
                <p
                  class="listing__details__income-requirement mb-0 fw-500 text-gray fs-14"
                >
                  <span v-if="listing.income_type == 'net'"
                    >{{ $t("Net income requirement") }}:
                  </span>
                  <span v-else>{{ $t("Gross income requirement") }}: </span>

                  <span class="ml-1" v-if="listing.rent3x">{{
                    $t("singleListing.actions.monthlyRent")
                  }}</span>
                  <span class="ml-1" v-else>€ {{ listing.preferredMinIncome }}</span>
                </p>
              </div>
            </div>
          </div>
          <hr class="w-100" />
          <div>
            <p class="fw-medium text-gray5 mb-1">{{ $t("broker_label") }}</p>
            <router-link :to="`/office/${listing.broker.slug}`">
              <h5 class="fw-500 mb-0 fs-18 text-capitalize mb-3">
                {{ listing.broker.name }}
              </h5>
              <img
                v-if="listing.broker.logo"
                class="listing__details__broker-logo mb-3"
                :src="listing.broker.logo"
              />
            </router-link>
          </div>
        </div>

        <div
          v-else
          class="text-center listing__agency d-flex justify-content-center flex-column align-items-center position-sticky border border-light-blue"
        >
          <h5 class="fw-bold mb-2">{{ incompleteProfileLocale.title }}</h5>
          <p class="mb-1">
            {{ incompleteProfileLocale.body }}
          </p>
          <RouterLink to="/user/profile" class="d-block w-100 mt-2 btn btn-primary">{{
            incompleteProfileLocale.linkText
          }}</RouterLink>
        </div>
      </div>
      <div class="card mb-4">
        <h5 class="fw-bold fs-22">{{ $t("description") }}</h5>
        <div
          v-if="showAll"
          class="text-gray listing__description"
          v-dompurify-html="listing.description"
        ></div>
        <div
          v-else
          class="text-gray listing__description readmore-fade"
          v-dompurify-html="listing.description.slice(0, 330)"
        ></div>
        <div v-if="listing.description.length > 330">
          <button class="btn btn-outline-primary btn-sm" @click="showAll = !showAll">
            <i class="fa-solid fa-plus"></i>
            {{ showAll ? $t("Less") : $t("More") }}
          </button>
        </div>
      </div>
      <div class="card mb-4">
        <div class="row">
          <div class="col-12">
            <h5 class="fw-bold fs-22">{{ $t("Rental details") }}</h5>
          </div>
        </div>
        <div class="row" v-for="(infoVal, infoKey) in info" :key="infoKey">
          <div class="col-md-6 pb-0 pb-md-2">
            <p class="text-gray fw-medium mb-0">{{ infoKey }}:</p>
          </div>
          <div class="col-md-6 pt-0 pt-md-2">
            <p class="fw-bold mb-0 text-left">
              {{ infoVal }}
            </p>
          </div>
        </div>
      </div>

      <div class="card mb-4">
        <div class="row">
          <div class="col-12">
            <h5 class="fw-bold fs-22">{{ $t("Preferences new tenant") }}</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <p class="text-gray mb-1 fw-medium">{{ $t("Household composition") }}:</p>
          </div>
          <div class="col-md-6">
            <Person :fill="personFill" id="person" />
            <b-tooltip custom-class="custom-tooltip" target="person" placement="top">
              <i18n
                class="mb-0"
                :path="
                  listing.preferredHousehold.indexOf('single') > -1
                    ? 'tooltips.single.preferred'
                    : 'tooltips.single.notPreferred'
                "
                tag="p"
              >
              </i18n>
            </b-tooltip>
            <People :fill="peopleFill" id="people" />
            <b-tooltip custom-class="custom-tooltip" target="people" placement="top">
              <i18n
                class="mb-0"
                :path="
                  listing.preferredHousehold.indexOf('couple') > -1
                    ? 'tooltips.couple.preferred'
                    : 'tooltips.couple.notPreferred'
                "
                tag="p"
              >
                <br />
              </i18n>
            </b-tooltip>
            <Team :fill="teamFill" id="team" class="ml-1" />
            <b-tooltip custom-class="custom-tooltip" target="team" placement="top">
              <i18n
                class="mb-0"
                :path="
                  listing.preferredHousehold.indexOf('family') > -1
                    ? 'tooltips.family.preferred'
                    : 'tooltips.family.notPreferred'
                "
                tag="p"
              >
                <br />
              </i18n>
            </b-tooltip>
            <Group :fill="groupOccupancyFill" id="group-occupancy" class="ml-1" />
            <b-tooltip
              custom-class="custom-tooltip"
              target="group-occupancy"
              placement="top"
            >
              <i18n
                class="mb-0"
                :path="
                  listing.preferredHousehold.indexOf('group occupancy') > -1
                    ? 'tooltips.groupOccupancy.preferred'
                    : 'tooltips.groupOccupancy.notPreferred'
                "
                tag="p"
              >
                <br />
              </i18n>
            </b-tooltip>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <p class="text-gray fw-medium">{{ $t("Pets allowed") }}:</p>
          </div>
          <div class="col-md-6">
            <Paw
              id="pets-permission"
              :fill="listing.preferredAnimals ? '#0a9250' : '#FE5F55'"
            />
            <b-tooltip
              custom-class="custom-tooltip"
              target="pets-permission"
              placement="top"
            >
              <i18n
                class="mb-0"
                :path="
                  listing.preferredAnimals
                    ? 'tooltips.pets.allowed'
                    : 'tooltips.pets.notAllowed'
                "
                tag="p"
              >
                <br />
              </i18n>
            </b-tooltip>
          </div>
        </div>
      </div>

      <div class="card mb-4">
        <div class="row">
          <div class="col-12">
            <h5 class="fw-bold fs-22">{{ $t("Features") }}</h5>
          </div>
        </div>

        <div class="row" v-for="(feature, key) in features" :key="key">
          <div class="col-md-6 pb-0 pb-md-2">
            <p class="text-gray mb-0 fw-medium listing__features__single">{{ key }}:</p>
          </div>
          <div class="col-md-6 pt-0 pt-md-2">
            <p class="mb-0 text-left fw-bold">{{ feature }}</p>
          </div>
        </div>
      </div>

      <div class="row pl-1">
        <div class="col-12">
          <h5 class="fw-bold fs-22">{{ $t("Map") }}</h5>
        </div>
        <div class="col-12">
          <GoogleMap
            :positions="[{ lat: Number(listing.lat), lng: Number(listing.lng) }]"
          />
        </div>
      </div>
    </BCol>
    <BCol
      md="4"
      class="mt-5 mt-sm-0 d-none d-md-block"
      v-if="getUserType === 'user' && listing.broker"
    >
      <div v-if="tenantsVerified" class="card">
        <p class="text-gray5 fw-medium mb-2">{{ $t("monthlyRent") }}</p>
        <p class="d-flex align-items-center">
          <span class="fs-24 fw-bold text-black"
            >{{ listing.price | formatCurrency }}
          </span>
          <span class="text-gray5 ml-2 fw-medium">/{{ $t("month") }}</span>
        </p>

        <div class="text-danger fw-bold" v-if="listing.assigned">
          {{ $t("Rented, this property is not available anymore") }}
        </div>
        <div
          class="text-danger fw-bold"
          v-else-if="
            listing.currentStatus == 'Assign a candidate' || listing.disable_viewing
          "
        >
          {{ $t("It’s not possible to request a viewing") }}
        </div>
        <div v-else>
          <div v-if="incomeSufficient == false">
            <b-tooltip
              custom-class="custom-tooltip"
              target="notSufficientIncome"
              placement="top"
            >
              {{ $t("Income not-sufficient") }}
            </b-tooltip>
            <div id="notSufficientIncome">
              <BButton
                variant="primary"
                class="d-block w-100 mt-4"
                disabled
                title="Income not-sufficient"
              >
                {{ $t("Schedule a viewing") }}
              </BButton>
            </div>
          </div>
          <BButton
            v-else
            v-b-modal.schedule-modal
            variant="primary"
            class="d-block w-100 mt-4"
          >
            {{ $t("Schedule a viewing") }}
          </BButton>
          <BButton
            variant="outline-primary"
            v-b-modal.send_message
            class="d-block w-100 mt-4"
          >
            {{ $t("ask_question") }}</BButton
          >
          <hr class="w-100" />

          <div class="d-flex align-items-center">
            <TickBox v-if="incomeSufficient" />
            <TimesBox v-else />
            <div class="ml-2 ml-lg-3">
              <p class="mb-0 fw-bold">
                {{
                  incomeSufficient ? $t("Income sufficient") : $t("Income insufficient")
                }}
              </p>
              <p class="listing__details__income-requirement mb-0 fw-500 text-gray fs-14">
                <span v-if="listing.income_type == 'net'"
                  >{{ $t("Net income requirement") }}:
                </span>
                <span v-else>{{ $t("Gross income requirement") }}: </span>
                <span class="ml-1" v-if="listing.rent3x">{{
                  $t("singleListing.actions.monthlyRent")
                }}</span>
                <span class="ml-1" v-else>€ {{ listing.preferredMinIncome }}</span>
              </p>
            </div>
          </div>
        </div>
        <hr class="w-100" />
        <div>
          <p class="fw-medium text-gray5 mb-1">{{ $t("Broker") }}</p>
          <router-link :to="`/office/${listing.broker.slug}`">
            <h5 class="fw-500 mb-0 fs-18 text-capitalize mb-3">
              {{ listing.broker.name }}
            </h5>
            <img
              v-if="listing.broker.logo"
              class="listing__details__broker-logo mb-3"
              :src="listing.broker.logo"
            />
          </router-link>
        </div>
      </div>

      <div v-else class="card text-center">
        <h5 class="fw-bold mb-2">{{ incompleteProfileLocale.title }}</h5>
        <p class="mb-1">
          {{ incompleteProfileLocale.body }}
        </p>
        <RouterLink to="/user/profile" class="d-block w-100 mt-2 btn btn-primary">{{
          incompleteProfileLocale.linkText
        }}</RouterLink>
      </div>
    </BCol>

    <BCol md="4" class="mt-5 mt-sm-0 d-none d-md-block" v-if="getUserType == null">
      <div class="card">
        <p class="text-gray5 fw-medium mb-2">{{ $t("Monthly rent") }}</p>
        <p class="d-flex align-items-center">
          <span class="fs-24 fw-bold text-black"
            >{{ listing.price | formatCurrency }}
          </span>
          <span class="text-gray5 ml-2 fw-medium">/{{ $t("month") }}</span>
        </p>

        <div class="text-gray">
          Register or Login to schedule a viewing for this listing
        </div>

        <router-link
          :to="`/user/register?ref_listing=${listing.id}`"
          class="btn btn-primary d-block mt-4"
        >
          {{ $t("Create an account") }}
        </router-link>
        <router-link to="/user/login" class="text-gray text-center mt-2">
          {{ $t("Login my profile") }}
        </router-link>
        <hr class="w-100" />
        <div>
          <p class="fw-medium text-gray5 mb-1">{{ $t("Broker") }}</p>

          <router-link :to="`/office/${listing.broker.slug}`">
            <h5 class="fw-500 mb-0 fs-18 text-capitalize mb-3">
              {{ listing.broker.name }}
            </h5>
            <img
              v-if="listing.broker.logo"
              class="listing__details__broker-logo mb-3"
              :src="listing.broker.logo"
            />
          </router-link>
        </div>
      </div>
    </BCol>

    <!-- <b-modal id="schedule-modal" hide-footer>
      <template v-slot:modal-header-close>
        <Times fill="#0A9250" />
      </template>
      <component @schedule-created="onScheduleCreated" :is="modalComp" />
    </b-modal> -->
    <ScheduleModal @schedule-created="onScheduleCreated" :listing="listing" />
    <SendMessageModal :listing="listing" />
  </BRow>
</template>

<script>
import ListingOverview from "../general/listing/ListingOverview.vue";
import Person from "@/components/svg/Person";
import People from "@/components/svg/People";
import Team from "@/components/svg/Team";
import Group from "@/components/svg/Group";
import Paw from "@/components/svg/Paw";
import TickBox from "@/components/svg/TickBox";
import { mapGetters } from "vuex";
import TimesBox from "../svg/TimesBox.vue";
import ScheduleModal from "./ScheduleModal.vue";
import axios from "axios";
import SendMessageModal from "../../views/Messages/SendMessageModal.vue";
import GoogleMap from "../common/GoogleMap.vue";
import moment from "moment";

export default {
  components: {
    ScheduleModal,
    ListingOverview,
    Person,
    People,
    Team,
    Paw,
    TickBox,
    TimesBox,
    Group,
    SendMessageModal,
    GoogleMap,
  },
  props: {
    listing: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isAuth", "getUserType", "tenantsVerified"]),
    listingOverview() {
      const {
        id,
        price,
        livingArea,
        totalRooms,
        totalBedrooms,
        interior,
        title,
        address,
        currentStatus,
      } = this.listing;
      return {
        status: currentStatus,
        id,
        price,
        livingArea,
        totalRooms,
        totalBedrooms,
        interior,
        title,
        address,
      };
    },
    info() {
      const listingStatus = this.$t("listingStatus");

      const liberalization = {
        does_not_apply: this.$t("Does not apply"),
        social_rent: this.$t("Social rent"),
        free_sector: this.$t("Free sector"),
      };

      const {
        published_at,
        currentStatus,
        deposit,
        serviceCost,
        availablityDate,
        exServiceCost,
        exUtilityCost,
        utilityCost,
      } = this.listing;

      let info = {
        "Listed since": moment(published_at).format("DD-MM-YYYY HH:mm"),
        Status: listingStatus[currentStatus],
        ...(deposit && { Deposit: this.$options.filters.formatCurrency(deposit) }),
        "Service costs":
          exServiceCost === true
            ? this.$options.filters.formatCurrency(serviceCost)
            : this.$t("singleListing.scIncluded"),

        ...(this.listing.exUtilityCost != null && {
          [this.$t("Estimated utility costs")]:
            exUtilityCost === true
              ? this.$t("Utility cost included")
              : this.$options.filters.formatCurrency(utilityCost),
        }),
        "Available per": availablityDate,
        [this.$t("rental_period")]:
          this.listing.rental_period_type == "temporary"
            ? this.listing.rental_period + " " + this.$t("months")
            : this.$t("Undetermined"),
        [this.$t("yearly_rent_increases")]: this.listing.is_yearly_increament
          ? `${this.listing.rent_increase_percentage}%`
          : this.$t("no"),
        ...(this.listing.recent_woz_value && {
          [this.$t("woz_value")]: this.$options.filters.formatCurrency(
            this.listing.recent_woz_value
          ),
        }),
        [this.$t("Liberalization")]: liberalization[this.listing.liberalization],
      };

      return info;
    },

    features() {
      const singleListingFeaturesLocacle = this.$t("singleListing.features.list");
      const propertyTypeLocale = this.$t("singleListing.propertyType");
      const outsideSpace = this.outsideSpace;
      const storage = this.storage;
      const parking = this.parking;
      const {
        propertyType,
        yearOfConstruction,
        livingArea,
        plotArea,
        energyLabel,
        volumeInCBM,
      } = this.listing;
      return {
        [singleListingFeaturesLocacle[0]]: propertyTypeLocale[propertyType.toLowerCase()],
        [singleListingFeaturesLocacle[5]]: outsideSpace,
        [propertyTypeLocale == "House"
          ? this.$t("number_of_floors")
          : this.$t("floor_level_text")]:
          this.listing.floor == 0 ? this.$t("Ground floor") : this.listing.floor,
        [singleListingFeaturesLocacle[6]]: energyLabel,
        [singleListingFeaturesLocacle[1]]: yearOfConstruction,
        [singleListingFeaturesLocacle[7]]: storage,
        [singleListingFeaturesLocacle[2]]: livingArea,
        [singleListingFeaturesLocacle[8]]: parking,
        [singleListingFeaturesLocacle[3]]: volumeInCBM,
        [this.$t("lift")]: this.listing.lift
          ? this.$t("present")
          : this.$t("not_present"),
        [singleListingFeaturesLocacle[4]]: plotArea || "N.v.t",
      };
    },

    incomeSufficient() {
      if (!this.user) return true;

      let grossMonthlyIncome = this.user.grossMonthlyIncome ?? 0;
      let netMonthlyIncome = this.user.net_monthly_income ?? 0;

      const income =
        (this.listing.income_type == "gross" ? grossMonthlyIncome : netMonthlyIncome) *
        this.user.tenants.length;

      const preferredMinIncome = this.listing.rent3x
        ? this.listing.price * 3
        : +this.listing.preferredMinIncome;

      if (+income < preferredMinIncome) {
        if (
          this.user.guarantor &&
          this.user.guarantor.personalMonthlyIncome >= preferredMinIncome
        ) {
          return true;
        }

        return false;
      }

      return true;
    },
    requestRoute() {
      if (!this.isAuth) return "/user/login";
      return (
        `/listings/${this.listing.id}` +
        +"/schedule?creationDate=" +
        this.listing.created_at
      );
    },
    personFill() {
      return this.listing.preferredHousehold.indexOf("single") > -1
        ? "#0a9250"
        : "#fe5f55";
    },
    peopleFill() {
      return this.listing.preferredHousehold.indexOf("couple") > -1
        ? "#0a9250"
        : "#fe5f55";
    },
    teamFill() {
      return this.listing.preferredHousehold.indexOf("family") > -1
        ? "#0a9250"
        : "#fe5f55";
    },
    groupOccupancyFill() {
      return this.listing.preferredHousehold.indexOf("group occupancy") > -1
        ? "#0a9250"
        : "#fe5f55";
    },

    outsideSpace() {
      const outsideSpaceLocale = this.$t("outsideSpace");
      const outsideSpaceRaw = this.listing.outsideSpace.toLowerCase();
      let outsideSpace = [];
      for (let key in outsideSpaceLocale) {
        if (outsideSpaceRaw.indexOf(key) > -1) {
          outsideSpace.push(outsideSpaceLocale[key]);
        }
      }
      return outsideSpace.join(", ");
    },
    storage() {
      const storageLocale = this.$t("storage");
      const storageRaw = this.listing.storage;
      let storage = [];
      for (let key in storageLocale) {
        if (storageRaw.indexOf(key) > -1) {
          storage.push(storageLocale[key]);
        }
      }
      return storage.join(", ");
    },
    parking() {
      const parkingLocale = this.$t("parking");
      const parkingRaw = this.listing.parkingFacilities.toLowerCase();
      let parking = [];
      for (let key in parkingLocale) {
        if (parkingRaw.indexOf(key) > -1) {
          parking.push(parkingLocale[key]);
        }
      }
      return parking.join(", ");
    },
    incompleteProfileLocale() {
      return this.$t("singleListing.incompleteProfile");
    },
  },
  methods: {
    async getCoordinatesFromAddress(address) {
      const queryObj = {
        ...address,
        limit: 1,
        format: "json",
      };
      const queryParams = new URLSearchParams(queryObj).toString();
      const url = `https://nominatim.openstreetmap.org/search?${queryParams}`;
      console.log("url", url);
      try {
        const { data } = await axios.get(url);
        this.$set(this.markerLatLng, 0, data[0].lat);
        this.$set(this.markerLatLng, 1, data[0].lon);
        this.$set(this.center, 0, data[0].lat);
        this.$set(this.center, 1, data[0].lon);
      } catch (error) {
        console.log(error.message);
      }
    },
    onScheduleCreated() {},
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [],
      markerLatLng: [],
      modalComp: "Schedule",
      showAll: false,
    };
  },
  mounted() {
    // this.getCoordinatesFromAddress({
    //   street: this.listing.houseNr + " " + this.listing.streetName,
    //   city: this.listing.city,
    //   country: "Netherlands",
    //   postalCode: this.listing.zipcode,
    // });
  },
};
</script>

<style lang="scss" scoped>
.listing {
  &__details,
  &__features {
    padding-bottom: 2.5rem;
  }

  &__agency {
    border-radius: 5px;
    border-width: 2px !important;
    padding: 2.4375rem 1.4375rem 1.3125rem 1.4375rem;
    top: 0;

    @include md {
      padding: 2.4375rem 1rem 1.3125rem 1rem;
    }
  }

  &__info-single {
    min-width: 220px;

    @include sm {
      flex-basis: auto;
    }
  }

  &__features {
    &__feature {
      flex-basis: 50%;
    }

    &__single {
      min-width: 220px;

      @include sm {
        flex-basis: auto;
      }
    }
  }

  &__details {
    &__broker-logo {
      max-width: 100px;
      height: auto;
    }
  }

  &__description p {
    margin-bottom: 0;
  }
}

#schedule-modal {
  .modal-dialog {
    max-width: 540px;
  }

  .modal-header {
    border: none;
    padding-bottom: 0;
  }

  .modal-body {
    padding: 2.5rem;

    @include xs {
      padding: 1rem;
    }

    padding-top: 0;
  }
}

.card {
  background: #ffffff;
  border: 1.5px solid #eeeeee;
  border-radius: 5px;
  padding: 2rem;
}

.readmore-fade {
  -webkit-mask-image: linear-gradient(180deg, #000 30%, transparent);
}
</style>
