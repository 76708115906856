<template>
  <Modal
    modalId="schedule-modal"
    id="schedule-modal"
    hide-footer
    hide-header
    :no-close-on-backdrop="false"
  >
    <RequestedModal v-if="requested" />
    <template v-else>
      <div class="row">
        <div class="col-md-12">
          <div class="fs-30px fw-700">
            {{ $t("Schedule a viewing") }}
          </div>
          <p class="text-gray">
            {{
              $t(
                "On this page, pass on a number of suggestions for a viewing moment to the broker. Tip: provide multiple options to increase the chance of a viewing."
              )
            }}
          </p>
          <div class="alert alert-warning" role="alert">
            {{
              $t("Provide a minimum of 5 options to increase the change of a viewing.")
            }}
          </div>
        </div>
        <div class="col-md-12 pt-0">
          <div class="card bg-gray7 mb-3" v-if="getListing.preferredViewingDates.length">
            <div class="card-body">
              <strong>
                {{ $t("Prefered dates by broker for viewing") }}
              </strong>
              <ul class="text-gray disc ml-4 mt-2 mb-2">
                <li
                  v-for="viewingDate in getListing.preferredViewingDates"
                  :key="viewingDate"
                >
                  {{ viewingDate }}
                </li>
              </ul>
            </div>
          </div>
          <div class="">
            <strong>
              {{ $t("Select multiple viewing options below") }}
            </strong>
          </div>
        </div>
      </div>

      <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="row" v-for="(viewing, key) in form.schedules" :key="key">
            <div class="col-md-6 py-0">
              <Datepicker
                rules="required"
                v-model="viewing.date"
                :placeholder="$t('Date')"
                :label="key == 0 ? $t('Date') : ''"
                name="date"
                :disablePastDates="true"
                :disableFutureDates="true"
                :disableWeekends="true"
                :disabledDates="disabledDates"
              />
            </div>
            <div class="col-md-6 py-0">
              <SelectInput
                rules="required"
                :label="key == 0 ? $t('Time') : ''"
                name="time"
                v-model="viewing.time"
              >
                <option value="No Preference">{{ $t("No Preference") }}</option>
                <option value="09:00 : 12:00">{{ $t("09:00 : 12:00") }}</option>
                <option value="13:00 : 17:00">{{ $t("13:00 : 17:00") }}</option>
              </SelectInput>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <b-button variant="white" block class="mb-3" @click="addSuggestions">
                <i class="fa-solid fa-plus text-primary"></i>
                {{ $t("Add extra date option") }}
              </b-button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <strong>
                {{ $t("Prefered starting date rent") }}
              </strong>
              <p class="text-gray">
                {{ $t("When will you be available to rent this property?") }}
              </p>

              <div class="card bg-gray7 mb-3" v-if="getListing.availablityDate">
                <div class="card-body">
                  <strong>
                    {{ $t("Availability date by broker") }}
                  </strong>
                  <ul class="text-gray disc ml-4 mt-2 mb-2">
                    <li>
                      {{ getListing.availablityDate }}
                    </li>
                  </ul>
                </div>
              </div>

              <Datepicker
                rules="required"
                v-model="form.availability_date"
                :placeholder="$t('Date')"
                :label="$t('Your availability date for this property')"
                name="date"
                :disablePastDates="true"
                :disableFutureDates="true"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <BButton
                :disabled="getLoadings.schedule"
                variant="primary"
                type="submit"
                class="w-100 d-block schedule__send"
                >{{ $t("schedule.submitBtn") }}

                <b-spinner
                  v-if="getLoadings.schedule"
                  small
                  label="Spinning"
                  variant="white"
                ></b-spinner>
              </BButton>
            </div>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
    <!-- <Form
      :formFields="formFields"
      @on-submit="onSubmit"
      @liveValueUpdate="onLiveValueUpdate"
      :emitLiveValuesOf="emitLiveValuesOf"
      :addWatcherOnce="false"
    >
      <template #formfooter>
        <div class="mb-3 d-flex"></div>
      </template>
    </Form> -->
  </Modal>
  <!-- <main class="schedule">
    <SectionHeading class="mb-3" :heading="$t('schedule.heading')" />
    <i18n path="schedule.text" class="schedule__instruction text-gray" tag="p">
      <br class="d-none d-sm-block" />
      <br class="d-none d-sm-block" />
    </i18n>

    <p class="text-danger" v-if="getErrors.schedule && getErrors.schedule.length">
      {{ getErrors.schedule }}
    </p>
    <div class="bg-light-gray p-3 mb-3">
      <p class="text-primary fw-bold mb-2">
        {{ $t("schedule.brokerPreferenceText") }}
      </p>
      <div class="row">
        <div class="col-7">
          <div class="row">
            <div
              class="col-6"
              v-for="viewingDate in getListing.preferredViewingDates"
              :key="viewingDate"
            >
              {{ viewingDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Form
      :formFields="formFields"
      @on-submit="onSubmit"
      @liveValueUpdate="onLiveValueUpdate"
      :emitLiveValuesOf="emitLiveValuesOf"
      :addWatcherOnce="false"
    >
      <template #formfooter>
        <div class="mb-3 d-flex">
          <ButtonWithIcon
            @click.native="addSuggestions"
            class="w-100 d-block text-black"
            :text="$t('schedule.addDate')"
          >
            <Plus />
          </ButtonWithIcon>
        </div>
        <BButton
          :disabled="getLoadings.schedule"
          variant="primary"
          type="submit"
          class="w-100 d-block schedule__send"
          >{{ $t("schedule.submitBtn") }}

          <b-spinner
            v-if="getLoadings.schedule"
            small
            label="Spinning"
            variant="white"
          ></b-spinner>
        </BButton>
      </template>
    </Form>
  </main> -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "../ui/Modal.vue";
// import Form from "@/components/ui/form/Form.vue";
import { ValidationObserver } from "vee-validate";
import Datepicker from "../ui/form/controls/Datepicker.vue";
import SelectInput from "../ui/form/controls/SelectInput.vue";
import RequestedModal from "./RequestedModal.vue";

export default {
  components: {
    Modal,
    ValidationObserver,
    Datepicker,
    SelectInput,
    RequestedModal,
  },
  props: ["listing"],
  data() {
    return {
      currentSuggestions: 1,
      formFields: [],
      emitLiveValuesOf: ["date1"],
      requested: false,
      form: {
        schedules: [
          {
            date: "",
            time: "No Preference",
          },
        ],
        availability_date: null,
      },
    };
  },
  computed: {
    ...mapGetters("listing", ["getListing"]),
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    futureDateTimestamp() {
      let creationDate = "";
      if (this.$route.query.creationDate)
        creationDate = new Date(this.$route.query.creationDate);
      else creationDate = new Date();
      creationDate.setMonth(creationDate.getMonth() + 1);
      return creationDate.getTime();
    },
    disabledDates() {
      return this.form.schedules.map((x) => x.date);
    },

    // formFields() {
    //   return [
    //     {
    //       name: "date1",
    //       component: "Datepicker",
    //       value: "",
    //       rules: "required",
    //       placeholder: this.$t("date"),
    //       colClasses: "col-12 col-sm-6 pr-sm-1 pr-3",
    //       disablePastDates: true,
    //       disableWeekends: true,
    //       disableFutureDates: true,
    //       futureDateTimestamp:this.futureDateTimestamp
    //     },
    //     {
    //       name: "time1",
    //       component: "SelectInput",
    //       value: "No Preference",
    //       rules: "required",
    //       placeholder: "Time",
    //       colClasses: "col-12 col-sm-6 pl-sm-1 pl-3",
    //       options: [
    //         {
    //           text: this.$t("schedule.noPreference"),
    //           value: "No Preference",
    //         },
    //         {
    //           text: "09:00 : 12:00",
    //           value: "09:00 : 12:00",
    //         },
    //         {
    //           text: "13:00 : 17:00",
    //           value: "13:00 : 17:00",
    //         },
    //       ],
    //     },
    //   ];
    // },
  },
  methods: {
    ...mapActions("listing", ["setSchedule", "setListing"]),
    addSuggestions() {
      this.form.schedules.push({
        date: "",
        time: "No Preference",
      });
      if (this.currentSuggestions >= 10) return;
      this.currentSuggestions += 1;
      this.formFields.push({
        name: `date${this.currentSuggestions}`,
        component: "Datepicker",
        value: "",
        rules: "required",
        label: this.$t("date"),
        placeholder: this.$t("date"),
        colClasses: "col-12 col-sm-6 pr-sm-1 pr-3",
        disablePastDates: true,
        disableFutureDates: true,
        disableWeekends: true,
        futureDateTimestamp: this.futureDateTimestamp,
      });
      this.formFields.push({
        name: `time${this.currentSuggestions}`,
        component: "SelectInput",
        value: "No Preference",
        rules: "required",
        placeholder: "Time",
        colClasses: "col-12 col-sm-6 pl-sm-1 pl-3",
        options: [
          {
            text: this.$t("schedule.noPreference"),
            value: "No Preference",
          },
          {
            text: "09:00 : 12:00",
            value: "09:00 : 12:00",
          },
          {
            text: "13:00 : 17:00",
            value: "13:00 : 17:00",
          },
        ],
      });
      this.emitLiveValuesOf.push(`date${this.currentSuggestions}`);
    },
    removeSuggestions() {
      if (this.currentSuggestions < 2) return;
      this.emitLiveValuesOf.pop();
      this.currentSuggestions -= 1;
      this.formFields.pop();
      this.formFields.pop();
    },
    async onSubmit() {
      console.log("onSubmit");
      try {
        this.form.listing_id = this.listing.id;
        console.log("this.form", this.form);
        await this.setSchedule(this.form);
        this.requested = true;
        this.$emit("schedule-created");
      } catch (error) {
        console.log(error.message);
      }
    },
    onLiveValueUpdate(data) {
      for (let key in data) {
        const currentDateArr = data[key].split("-");
        const date = `${currentDateArr[1]}-${currentDateArr[0]}-${currentDateArr[2]}`;
        this.disabledDates[key] = date;
      }
      this.formFields.forEach((field, i) => {
        if (field.name.indexOf("date") > -1) {
          const newField = { ...field };
          newField.disabledDates = Object.values(this.disabledDates);
          this.formFields.splice(i, 1, newField);
        }
      });
    },
  },
  created() {
    this.formFields.push({
      name: "date1",
      component: "Datepicker",
      value: "",
      rules: "required",
      label: this.$t("date"),
      placeholder: this.$t("date"),
      colClasses: "col-12 col-sm-6 pr-sm-1 pr-3",
      disablePastDates: true,
      disableWeekends: true,
      disableFutureDates: true,
      futureDateTimestamp: this.futureDateTimestamp,
      disabledDates: Object.values(this.disabledDates),
    });
    this.formFields.push({
      name: "time1",
      component: "SelectInput",
      value: "No Preference",
      rules: "required",
      label: this.$t("Time"),
      placeholder: "Time",
      colClasses: "col-12 col-sm-6 pl-sm-1 pl-3",
      options: [
        {
          text: this.$t("schedule.noPreference"),
          value: "No Preference",
        },
        {
          text: "09:00 : 12:00",
          value: "09:00 : 12:00",
        },
        {
          text: "13:00 : 17:00",
          value: "13:00 : 17:00",
        },
      ],
    });
    // const listingId = this.$route.params.listingId;
    // this.setListing({ query: "type=schedule", listingId });
  },
  watch: {
    "$i18n.locale": function () {
      this.formFields.forEach((field, i) => {
        const newField = { ...field };
        if (field.name.indexOf("time") > -1) {
          newField.options.splice(0, 1, {
            text: this.$t("schedule.noPreference"),
            value: "No Preference",
          });
        }

        this.formFields.splice(i, 1, newField);
      });
    },
  },
};
</script>

<style lang="scss">
.schedule {
  &__send {
    width: 75%;
    @include xs {
      width: 100%;
    }
  }
  .custom-select {
    background: #fff
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIzSURBVHgBtVVbjgFREL0YIiLGEmZWMD0rmPbj+TGsACtoVoAVYAVYAT5E8MHswA7GEpqESLzmHLktrV1iJG5Sbt9Hnao6VXUJ8aThuudSu90O+/3+ML/X67WZyWTMh4EJFgwGjcPhoGOpO46nkPput5skEonZ3cCDwSDtdrsbABX7/b6FbwLRyBxzDZKGfLlcR/VKNBptOjHczo3RaFSCQhvetJbL5Xs8Hi9IRRP7v7FYrAPJwWAEhn6w36COuDWGw2EOcoDHZedZr9fT+v3+myK6stRJCxUVVPJ4PGN8TuBhXvxjAJj0ZBmhldgTFQDVySkoqKiUGS4jUp15vd4y50AgULD27Bwb4PBqlmV1fKnOIpEIvWxB//sMeDwes0Y1KHfF44OVo7FMuXjhD4o+DCoELDJcY7PZFFOp1PQeNNzXMFUhR8BQKPSKyTwrN3g8wzRTAcAow9WdlYGcmFLnrBuPwGhXUyp3WREqb7fbbZHJZeXYwZkT6uCszvVisZifgCX5ExS9Jq4MAsimuACXTjFx04tyYxehdbMW+bfAGTbAT/ekER201K29F+vD5/PVkDQDDw/bs3gLHNOnfQ9GSoyEUVt7J49JBw7JVUHV+2xpinNf3uXbUbH3wFlV8IEBcAXC/m/YeUR3VSGGtSZlAK3yLnUA2rRjKZ9NAObAd0k+ixM2Dmuc5cjKwfyB8xzDR7Xkk8lkx4lx9aG3EgL+6KWTgimNrVar2rV/k6f9NT1t/AFGq0xXiPVRoAAAAABJRU5ErkJggg==)
      no-repeat right 0.75rem center/18px 19.42px;
  }
}
</style>
