<template>
  <!-- <p class="fw-bold fs-18">{{ listing.price | formatCurrency }} {{$t('perMonth')}}</p> -->
  <div>
    <div class="d-block d-sm-none">
      <div
        class="listing__overview d-flex flex-wrap flex-md-nowrap justify-content-between"
      >
        <div class="">
          <div class="d-flex">
            <MeasureTape />
            <span class="fw-bold ml-2">{{ listing.livingArea }}</span>
          </div>
        </div>
        <div class="">
          <div class="d-flex">
            <Door stroke="#AEAEAE" />
            <span class="fw-bold ml-2">{{ listing.totalRooms }}</span>
          </div>
        </div>
        <div class="">
          <div class="d-flex" v-if="listing.totalBedrooms">
            <Bed />
            <span class="fw-bold ml-2">{{ listing.totalBedrooms }} </span>
          </div>
        </div>
        <div class="">
          <div class="d-flex" v-if="listing.interior">
            <Sofa fill="#AEAEAE" />
            <span class="fw-bold ml-2">{{ listingInterior }}</span>
          </div>
        </div>
        <div class="mt-4 d-flex">
          <p class="mr-3 text-gray5">{{ $t("status") }}</p>
          <div class="d-flex" v-if="listing.status">
            <span class="fw-bold">{{ $t("listingStatus")[listing.status] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-sm-block">
      <div
        class="listing__overview d-flex flex-wrap flex-md-nowrap justify-content-between"
      >
        <div class="ml-2">
          <p class="text-gray5">{{ $t("livingArea") }}</p>
          <div class="d-flex">
            <MeasureTape />
            <span class="fw-bold ml-2">{{ listing.livingArea }}</span>
          </div>
        </div>
        <div class="ml-2">
          <p class="text-gray5">{{ $t("rooms") }}</p>
          <div class="d-flex">
            <Door stroke="#AEAEAE" />
            <span class="fw-bold ml-2">{{ listing.totalRooms }}</span>
          </div>
        </div>
        <div class="ml-2">
          <p class="text-gray5">{{ $t("bedRooms") }}</p>
          <div class="d-flex" v-if="listing.totalBedrooms">
            <Bed />
            <span class="fw-bold ml-2">{{ listing.totalBedrooms }} </span>
          </div>
        </div>
        <div class="ml-2">
          <p class="text-gray5">{{ $t("interior") }}</p>
          <div class="d-flex" v-if="listing.interior">
            <Sofa fill="#AEAEAE" />
            <span class="fw-bold ml-2">{{ listingInterior }}</span>
          </div>
        </div>
        <div class="ml-2">
          <p class="text-gray5">{{ $t("status") }}</p>
          <div class="d-flex" v-if="listing.status">
            <span class="fw-bold">{{ $t("listingStatus")[listing.status] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeasureTape from "@/components/svg/MeasureTape";
import Door from "@/components/svg/Door";
import Sofa from "@/components/svg/Sofa";
import Bed from "@/components/svg/Bed";
export default {
  components: { MeasureTape, Door, Sofa, Bed },
  props: {
    listing: {
      type: Object,
      required: true,
    },
  },
  computed: {
    listingInterior() {
      const interior = this.$t("singleListing.interior");
      return interior[this.listing.interior.toLowerCase()];
    },
  },
};
</script>

<style lang="scss" scoped>
.listing {
  &__overview {
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    border-radius: 5px;
    padding: 2rem;
  }
}
</style>
