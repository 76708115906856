<template>
  <div class="single-listing">
    <BContainer v-if="listing">
      <div class="mb-2">
        <router-link
          :to="getUserType == 'broker' ? '/broker/listings' : '/listings?page=1'"
          class="fw-medium text-primary"
        >
          &lt; {{ $t("back-listing-overview") }}
        </router-link>
      </div>

      <h4 class="single-listing__name fw-bold text-capitalize">
        {{ listing.title }}
      </h4>
      <p v-if="listing" class="single-listing__address text-gray fw-medium">
        {{ listing.address }}
      </p>
      <h5 class="text-danger" v-if="getErrors.listing && getErrors.listing.length">
        {{ getErrors.listing }}
      </h5>
      <Spinner v-else-if="getLoadings.set_listing" />
      <template v-else>
        <Gallery :slides="slides" class="mb-4" v-if="slides.length" />
        <ListingDetails v-if="listing" :listing="listing" />
      </template>
    </BContainer>
  </div>
</template>

<script>
import Gallery from "../components/Listing/Gallery.vue";
import ListingDetails from "../components/Listing/ListingDetails.vue";
import { mapActions, mapGetters } from "vuex";
import Spinner from "../components/ui/Spinner.vue";
export default {
  components: { Gallery, ListingDetails, Spinner },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("listing", ["listing"]),
    ...mapGetters("auth", ["getUserType"]),
    listingTitle() {
      if (!this.listing || !this.listing.title) return "";
      const propertyTypes = this.$t("singleListing.propertyType");
      const listingTitleArr = this.listing.title.split(" ");

      listingTitleArr[0] = propertyTypes[listingTitleArr[0].toLowerCase()];
      return listingTitleArr.join(" ");
    },
    slides() {
      if (!this.listing) return [];
      return this.listing.photos;
    },
  },
  methods: {
    ...mapActions("listing", ["setListing"]),
  },
  created() {
    const userType = this.getUserType;
    const listingId = this.$route.params.listingId;

    this.setListing({ listingId, type: userType });
  },
};
</script>

<style lang="scss">
.single-listing {
  margin-bottom: 2.25rem;

  &__name {
    @include font(2.25rem, $dark2, bold);
  }

  &__listing {
    @include font(1.125rem, $gray, medium);
  }
}
</style>
