<template>
  <div class="px-3 px-sm-0">
    <SectionHeading class="mb-3" :heading="$t('requested.heading')" />
    <i18n path="requested.text" class="request__text mb-sm-4 mb-4 text-gray" tag="p">
      <br />
    </i18n>

    <Timeline :steps="steps" class="mb-sm-6 mb-4 steps__requested" />
    <BButton
      @click="$router.push('/listings/saved')"
      variant="primary"
      class="d-block w-100"
    >
      {{ $t("requested.submitBtn") }}
    </BButton>
  </div>
</template>

<script>
import SectionHeading from "@/components/general/SectionHeading.vue";
import Timeline from "@/components/general/Timeline.vue";
export default {
  components: { SectionHeading, Timeline },
  computed: {
    steps() {
      const stepsLocale = this.$t("requested.list");
      return stepsLocale.map((step, i) => ({
        text: `requested.list[${i}]`,
        completed: i === 0 ? true : false,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.requested {
  &__text {
    @include font(1.125rem, $gray3);
  }
}

.steps {
  &__requested {
    max-width: 87%;
  }
}
</style>
