<template>
  <Modal
    modalId="send_message"
    id="send_message"
    hide-footer
    hide-header
    :no-close-on-backdrop="false"
  >
    <template v-slot:modal-header>
      <strong>{{ $t("ask_question_about_property") }}</strong>
    </template>
    <template v-slot:modal-header-close>
      <Times fill="#0A9250" />
    </template>
    <div class="row">
      <div class="col-md-12">
        <div class="fs-30px fw-700">
          {{ $t("Ask a question") }}
        </div>
      </div>
    </div>

    <div class="card bg-gray7 p-3 my-3">
      <strong>{{ $t("be_aware") }} </strong>
      <p class="text-gray">{{ $t("be_aware_message") }}</p>
    </div>

    <label for="">{{ $t("message") }}</label>
    <textarea
      name=""
      id=""
      rows="4"
      class="form-control p-3"
      v-model="message"
    ></textarea>

    <b-alert variant="success" show class="mt-3" v-if="showSuccess">Success</b-alert>

    <BButton
      variant="primary"
      class="d-block w-100 mt-4"
      @click="send"
      :disabled="getLoadings.send_message"
    >
      {{ $t("send_message_to_broker") }}
      <b-spinner
        v-if="getLoadings.send_message"
        small
        label="Spinning"
        variant="white"
      ></b-spinner>
    </BButton>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Times from "../../components/svg/Times.vue";
import Modal from "../../components/ui/Modal.vue";
export default {
  components: {
    Times,
    Modal,
  },
  props: {
    listing: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: "",
      showSuccess: false,
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
  },
  methods: {
    ...mapActions("messages", ["sendMessage"]),
    async send() {
      if (!this.message) {
        return;
      }
      this.showSuccess = false;
      let data = {
        message: this.message,
        listing_id: this.listing.id,
        type: "text",
      };
      await this.sendMessage(data);
      this.message = "";
      this.showSuccess = true;
      this.$router.push("/messages");
    },
  },
  mounted() {},
};
</script>

<style lang="scss"></style>
