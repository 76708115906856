<template>
  <div class="d-flex steps mx-auto flex-wrap flex-sm-nowrap">
    <div
      class="step position-relative text-center text-sm-left"
      :class="{
        'right-border': i < steps.length - 1,
        completed: step.completed,
      }"
      v-for="(step, i) in steps"
      :key="i"
      :style="{ flexGrow: i < steps.length - 1 ? 1 : 0 }"
    >
      <div
        class="step__circle rounded-circle text-white d-flex align-items-center justify-content-center position-relative"
      >
        <Tick :width="15" :height="13" v-if="step.completed" />
        <span class="step__number" v-else>{{ i + 1 }}</span>
      </div>

        <i18n
          :path="step.text"
          class="mb-0 fw-bold text-gray d-inline-block step__text text-center"
          :class="{ first: i === 0 }"
          tag="p"
        >
          <br />
        </i18n>
    
    </div>
  </div>
</template>

<script>
import Tick from "../svg/Tick.vue";
export default {
  components: { Tick },
  props: {
    steps: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  // &__requested{
  //     max-width: 87%;
  // }
  @include xs {
    max-width: 100%;
  }
}
.step {
  @include xs {
    flex: 0 0 50%;
    max-width: 50%;
  }
  &__circle {
    width: 28px;
    height: 28px;
    background: $border-color;
    z-index: 2;
    margin-bottom: 10px;
    @include xs {
      margin: auto;
    }
  }
  &__number {
    font-size: 12px;
  }

  &.right-border {
    &::after {
      @include pseudo;
      width: 63%;
      height: 1.19px;
      background: $gray5;
      top: 13px;
      left: 50%;
      transform: translateX(-34%);
      @include xs {
        display: none;
      }
    }
  }
  &__text {
    position: absolute;
    transform: translateX(-20.2%);
    font-size: 12px;
    font-weight: 500;
    &.first {
      transform: translateX(-31%);
      @include xs {
        transform: none;
      }
    }
    @include xs {
      position: static;
      transform: none;
      font-size: 14px;
    }
  }
  &.completed {
    .step__circle {
      background: #0a9250;
    }
  }
}
</style>
