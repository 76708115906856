<template>
  <div>
    <b-row>
      <b-col cols="12" md="8">
        <div class="position-relative h-100">
          <img
            @click="showLightbox(0)"
            class="w-100 single-listing__img d-block"
            style="object-fit: cover; max-height: 624px"
            v-lazy="slides[0].name"
            alt="slide"
          />
          <BButton
            @click="showLightbox(0)"
            class="position-absolute bg-white single-listing__photos-btn d-block d-md-none"
            variant="outline-primary"
            >{{ $t("Check all photos") }}</BButton
          >
        </div>
      </b-col>
      <b-col cols="12" md="4">
        <b-row class="px-1">
          <b-col cols="6" md="12">
            <div
              class="height-300 rightImage"
              v-if="slides[1]"
              @click="showLightbox(0)"
              :style="{ backgroundImage: 'url(' + slides[1].name + ')' }"
            ></div>
          </b-col>
          <b-col cols="6" md="12" v-if="slides[2]">
            <div
              class="height-300 rightImage"
              v-if="slides[2]"
              @click="showLightbox(0)"
              :style="{ backgroundImage: 'url(' + slides[2].name + ')' }"
            ></div>
            <BButton
              @click="showLightbox(0)"
              class="position-absolute bg-white single-listing__photos-btn d-none d-md-block"
              variant="outline-primary"
              >{{ $t("Check all photos") }}
            </BButton>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- <Swiper class="swiper" :options="swiperOptions">
        <SwiperSlide
        class="cursor-pointer"
          @click.native="showLightbox(i)"
          v-for="(slide, i) in slides"
          :key="slide.id"
        >
          <img class="img-fluid" v-lazy="slide.name" alt="slide" />
        </SwiperSlide>
      </Swiper> -->
    <!-- <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div> -->
    <LightBox :media="lightboxImgs" :showLightBox="visible" v-if="visible"></LightBox>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import LightBox from "vue-image-lightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

import "swiper/css/swiper.css";
export default {
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      index: 0,
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1,
        spaceBetween: 5,
        breakpoints: {
          500: {
            slidesPerView: 1.4,
          },
          700: {
            slidesPerView: 2.4,
          },
          1500: {
            slidesPerView: 3.4,
          },
        },
      },
    };
  },
  computed: {
    lightboxImgs() {
      return this.slides.map((slide) => ({
        src: slide.name,
        thumb: slide.name,
      }));
    },
  },
  methods: {
    showLightbox(i) {
      console.log("showLightbox", i);
      this.index = i;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
  components: {
    // Swiper,
    // SwiperSlide,
    LightBox,
  },
};
</script>

<style lang="scss">
img {
  cursor: pointer;
}
.rightImage {
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  cursor: pointer;
}

.single-listing {
  &__img {
    border-radius: 5px;
  }
  &__lightbox {
    .vel-toolbar {
      display: none !important;
    }
  }

  &__photos-btn {
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1rem;
    min-width: 12.5rem;

    &:hover {
      color: $primary !important;
    }
    @include md {
      left: auto;
      right: 10px;
      transform: none;
    }
  }
}
</style>
