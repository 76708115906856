<template>
  <div>
    <div class="map w-100" id="map"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  props: ["positions"],
  components: {},
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["getUser", "isAuth"]),
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 15,
      center: { lat: 52.1326, lng: 5.2913 },
      markerLatLng: [],
      suitableListings: [],
      nonSuitableListings: [],
      modalComp: "Schedule",
      listing: null,
      loading: false,
      filters: {},
    };
  },
  async created() {
    let scriptId = "map-api-script";
    let mapAlreadyAttached = !!document.getElementById(scriptId);

    if (mapAlreadyAttached) {
      // if (window.google)
      // Script attached but may not finished loading; so check for 'google' object.
      // callback();
    } else {
      window.mapApiInitialized = this.mapInit;
      const GOOGLE_MAP_API_KEY = process.env.VUE_APP_GOOGLE_MAP_API_KEY;
      let script = document.createElement("script");
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&callback=mapApiInitialized&libraries=marker&v=beta`;
      document.body.appendChild(script);
    }
  },
  mounted() {},
  methods: {
    mapInit() {
      console.log("mapInit", this.zoom, this.center);
      this.center = {
        lat: this.positions[0].lat,
        lng: this.positions[0].lng,
      };
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: this.center,
        zoom: this.zoom,
        mapId: "4504f8b37365c3d0",
      });

      for (let position of this.positions) {
        this.addMarker(map, position);
      }
    },
    addMarker(map, position) {
      const marker = new window.google.maps.Marker({
        map,
        position: position,
      });
      marker.addListener("click", () => {
        this.$emit("marker-clicked", marker);
      });
    },
    async getCoordinatesFromAddress(address) {
      console.log("address", address);
      const queryObj = {
        ...address,
        limit: 1,
        format: "json",
      };
      const queryParams = new URLSearchParams(queryObj).toString();
      const url = `https://nominatim.openstreetmap.org/search?${queryParams}`;
      console.log("url", url);
      try {
        const { data } = await axios.get(url);
        if (data.length) {
          this.center = { lat: Number(data[0].lat), lng: Number(data[0].lon) };
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
<style>
.map {
  height: 300px;
}
</style>
